.staking-element{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 16px 16px 78px;
    max-width: 300px;
    flex-grow: 2;
    position: relative;
}
.nft-video{
    overflow: hidden;
    border-radius: 20px;
}

.ticket-container{
    border-radius: 0 0 16px 16px;
    width: 300px;
}

.nft-video-container{
    position: relative;
    border-radius: 20px;
    /*overflow: hidden;*/
}

.price{
    position: absolute;
    left: 8px;
    top: calc(100% + 4px);
}

.buy-button{
    transition: all 0.3s;
    outline: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0.5);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    border-radius: 20px;
}

.nft-video-container:hover .buy-button{
    opacity: 1;
    transform: scale(1);
}

.buy-button.paywall{
    opacity: 1;
    transform: scale(1);
}

.total{
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 40px;
    text-align: center;
}

.buy-button:focus{
    outline: none;
}

.owned-marker{
    position: absolute;
    font-size: 16px;
    font-weight: bolder;
    bottom: calc(100%);
    left: -2px;
    padding: 0px 4px;
}

.border-t-1{
    border: 2px solid #EBF7D4;
}
.border-t-1 .owned-marker{
    color: #EBF7D4;
}

.tickets{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    height: 300px;

    border-radius: 20px;

    transition: all 0.3s;
    opacity: 0;
    transform: scale(0.5);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
}

.nft-video-container:hover .tickets{
    opacity: 1;
    transform: scale(1);
}

.tickets-title{
    font-weight: bolder;
}

.tickets-flex{
    display: flex;
    flex-wrap: wrap;
    /*background: black;*/
    align-items: center;
    justify-content: center;
}

.ticket-number{
    margin: 4px;
    font-family: monospace;
    font-weight: bold;
    padding: 4px;
    width: 40px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.border-t-2{
    border: 2px solid #ABF0DF;
}
.border-t-2 .owned-marker{
    color: #ABF0DF;
}

.border-t-3{
    border: 2px solid #B74F53;
}
.border-t-3 .owned-marker{
    color: #B74F53;
}


.border-t-4{
    border: 2px solid rgba(217, 65, 60, 0.2);
    box-shadow: 5px 5px 20px 10px rgba(217, 65, 60, 0.2), -5px -5px 20px 10px rgba(229, 212, 26, 0.2);
}
.border-t-4 .owned-marker{
    color: #D9413C;
}